<template>
    <div>
        <div class="page_footer">
          <div class="container_box">
            <el-row>
              <!-- <el-col :sm="8" :xs="24">
                <img src="../assets/images/Logo.png" class="icon icon_foot" @click="goHome" />
                <div class="copyright">
                  <div>一般社団法人国際教育支援機構</div>
                  <div>Copyright (C)  IESO All Rights Reserved.</div>
                </div>
              </el-col> -->
              <el-col :sm="24" :xs="24">
                <div class="nav_foot" style="text-align:center" v-if="accountType!=1&&accountType2!=1">
                  <span @click="goHome">首页</span>&nbsp;&nbsp;|&nbsp;
                  <span @click="goTeam">师资团队</span>&nbsp;&nbsp;|&nbsp;
                  <span @click="goCourse">公益板块</span>&nbsp;&nbsp;|&nbsp;
                  <span @click="goLearn">公益心声</span>&nbsp;&nbsp;|&nbsp;
                  <!-- <span @click="goAbout">关于IESO</span>&nbsp;&nbsp;|&nbsp; -->
                  <span @click="goNewsList">新闻资讯</span>
                  <!-- <span @click="goPersonInfo">隐私政策</span> -->
                </div>
                <div class="record_box"><span style="margin-right:10px;">指导单位：山东省青年志愿者协会</span> 备案号：<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备2024116671号-1
                </a></div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="page_common_right">
          <div class="right_box"  @mouseleave="suspIsShow=false" @mouseenter="suspIsShow=true">
              <img class="ewm" src="../assets/images/icon-wechat.png" alt="">
              微信公众号
              <div class="img_box" v-show="suspIsShow">
                <img src="../assets/images/gzh-new.jpg">
              </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      suspIsShow:false,
      navList: [
        {
          title: '首页'
        },
        {
          title: '师资团队'
        },
        {
          title: '公益板块'
        },
        {
          title: '公益心声'
        },
        {
          title: '关于我们'
        }
      ],
      // teaToken: '',
      defalutIndex: 0,
      btnShow: false,
      searchKey: '',
      isShow: false, //小屏幕路由显示
      seen: false,
      commonShow: false,
      teacherType: 0,
      avatar: '',
      ws: null,
      newsNum: 0,
      // token:'',
      // accountType:'',
      groupId:'', //有groupId说明已经是会员 否则不是
      overdue:false,//会员是否过期
    }
  },
  methods: {
    gotoNewsCenter() {
      this.$router.push({ name: 'news_center_person' })
    },
    //个人中心显示隐藏
    getVisible() {
      this.seen = !this.seen
    },
    getDisplay() {
      this.seen = !this.seen
    },
    //搜索框的显示
    getShowserch() {
      this.commonShow = !this.commonShow
      this.searchKey = '';
      this.getSearch2();
    },
    //首页
    goHome() {
      this.$router.push({ path: '/' })
      this.isShow = false
    },
    //师资团队
    goTeam() {
      this.$router.push({ path: '/team' })
      this.isShow = false
    },
    //公益板块
    goCourse() {
      this.$router.push({ path: '/course' })
      this.isShow = false
    },
    //公益心声
    goLearn() {
      this.$router.push({ path: '/learn' })
      this.isShow = false
    },
    goNewsList(){
      this.$router.push({path:'/newslist'})
      this.isShow = false
    },
    //关于我们
    goAbout(){
      this.$router.push({path:'/about-us'})
      this.isShow = false
    },
    //个人信息保护方针
    goPersonInfo(){
      this.$router.push({path:'/personal-info'})
      this.isShow = false
    },
    //登录页
    toLogin() {
      this.isShow = false
      this.$router.push({ path: '/logreg/login' })
    },
    //注册页
    toRegister() {
      this.isShow = false
      this.$router.push({ path: '/logreg/register' })
    },
    //个人主页
    goPerson() {
      if (window.screen.width <= 767) {
        this.isShow = !this.isShow
      }
      this.$router.push({
        path: '/personal/person_home',
        query: { studentId: this.studentId }
      })
    },
    //企业中心
    goBusiness() {
      this.$router.push({
        path: '/business_center/business_home',
        query: { companyId: this.companyId }
      })
    },
    //搜索
    getSearch() {
      // this.$store.dispatch('user/saveSearch',this.searchKey);
      // console.log(this.$route.matched[0].instances.default);
      // console.log(this)
      this.$route.matched[0].instances.default.postData1.searchKey = this.searchKey
      this.$route.matched[0].instances.default.getData()
    },
    // getSearch2() {
    //   if (
    //     this.$route.path == '/' ||
    //     this.$route.path.substring(0, 7) == '/detail'
    //   ) {
    //     this.$router.push({ path: '/course' })
    //     this.searchKey = ''
    //   }
    // },
    getSearch2() {
      if (
        this.$route.path == '/' ||
        this.$route.path.substring(0, 7) == '/detail'
      ) {
        this.$router.push({ path: '/course' })
        // this.searchKey = ''
      }else{
        // console.log(this)
        this.$route.matched[0].instances.default.postData1.searchKey = this.searchKey
        this.$route.matched[0].instances.default.getData()
        // this.searchKey = ''
      }
    },
    // getSearch2(){
    //   if(this.$route.path == '/' ||
    //     this.$route.path.substring(0, 7) == '/detail'
    //     ){
    //       if(this.searchKey==''){
    //         this.$router.push({ path: '/course' })
    //       }else{
    //         console.log(this)
    //         this.$route.matched[0].instances.default.postData1.searchKey = this.searchKey
    //         this.$route.matched[0].instances.default.getData()
    //       }
    //     }else{
    //       this.$route.matched[0].instances.default.postData1.searchKey = this.searchKey
    //       this.$route.matched[0].instances.default.getData()
    //     }
    // },
    //退出登录
    toQuit() {
      this.$confirm('确定要退出登录？', '确认', {
        confirmButtonText: '退出',
        cancelButtonText: '取消'
      })
        .then(() => {
          localStorage.removeItem('accountType')
          localStorage.removeItem('username')
          localStorage.removeItem('token')
          localStorage.removeItem('studentId')
          localStorage.removeItem('accountId')
          localStorage.removeItem('studentGroupId')
          localStorage.removeItem('area')
          localStorage.removeItem('startDate')
          localStorage.removeItem('endDate')
          localStorage.removeItem('teacherId')
          localStorage.removeItem('teacherArea')
          localStorage.removeItem('phoneNumber')
          localStorage.removeItem('realName')
          this.$store.dispatch('user/saveUser', '')
          this.$store.dispatch('teacher/saveUser', '')
          if (
            this.$route.path.substring(0, 5) == '/team' ||
            this.$route.path.substring(0, 7) == '/course'||
            this.$route.path.substring(0, 6) == '/learn'
          ){
            this.$router.go(0)
          } else {
            this.$router.push({ path: '/' })
          }
          this.$message({
            type: 'success',
            message: '退出成功'
          })
        })
        .catch(() => {})
    },
    //志愿者登录
    toTeacherLogin() {
      if (this.teacherToken) {
        this.$router.push({ path: '/teacher_center/teacher_book' })
      } else {
        this.$router.push({ path: '/teacher_center/teacher_login' })
      }
    },
    //教师退出
    getQuitTeacher() {
      localStorage.removeItem('teacherType')
      this.$router.push({ path: '/' })
      this.$router.go(0)
    },
    ...mapMutations({
      saveUser: 'user/saveUser'
    }),
    //打开小屏幕  路由切换
    toOpenSmall() {
      this.isShow = !this.isShow
      console.log(this.isShow, '测试小屏幕')
    },
    //关闭小屏幕
    getClose() {
      this.isShow = !this.isShow
    },
    connectWs(){
      if ("WebSocket" in window){
        let wsUrl = this.wsUrl
        let accountId =localStorage.getItem('accountId')
        if(accountId && accountId !== 'undefined'){
          // 打开一个 web socket
          this.ws = new WebSocket(`${wsUrl}/ws/not-read-msg-num/${accountId}`);
          this.ws.onopen = function(){
            // Web Socket 已连接上，使用 send() 方法发送数据
            // ws.send("发送数据");
            console.log('连接成功')
          };
          var that = this
          this.ws.onmessage = function (evt) { 
            var received_msg = evt.data;
            var jsonObject = JSON.parse(received_msg)
            that.newsNum = jsonObject.data
            // console.log(that.newsNum)
          };
          this.ws.onclose = function(){ 
            // 关闭 websocket
            console.log("连接已关闭..."); 
          };
        } 
      } else {
        // 浏览器不支持 WebSocket
        alert("您的浏览器不支持 WebSocket!");
      }
    },
    getNews(){
      var accountId =localStorage.getItem('accountId')
      if(accountId && accountId !== 'undefined') {
        this.$axios.get('/message/num/'+accountId).then(res=>{
          this.newsNum = res.data.data
        })
      }
    },
  },
  computed: {
    currentPath() {
      return this.$route.path
    },
    logregPath() {
      return this.$route.path.substring(0, 7)
    },
    ...mapState({
      token: state => state.user.token,
      accountType: state => state.user.accountType,
      accountType2: state => state.teacher.accountType,
      studentId: state => state.user.studentId,
      companyId: state => state.user.companyId,
      // avatar: state => state.user.avatar,
      teacherToken: state => state.teacher.token,
    })
    // ...mapGetters(['getAvatar'])
  },
  watch:{
    $route(to,from){
      this.groupId = localStorage.getItem('studentGroupId')
      var endtime = localStorage.getItem('endDate')
      var stringTime = Date.parse(new Date(endtime))
      var nowtime = new Date().getTime()
      if(nowtime>stringTime){ //如果当前时间的时间戳大于会员截止时间的时间戳 则会员过期
        this.overdue = true
      }else{
        this.overdue = false
      }
      // console.log(this.groupId,stringTime,nowtime,this.overdue,'stringTime')
    }
  },
  mounted() {
    this.groupId = localStorage.getItem('studentGroupId')
    var endtime = localStorage.getItem('endDate')
    var stringTime = Date.parse(new Date(endtime))
    var nowtime = new Date().getTime()
    if(nowtime>stringTime){ //如果当前时间的时间戳大于会员截止时间的时间戳 则会员过期
      this.overdue = true
    }else{
      this.overdue = false
    }

    this.$bus.on('login',()=>{
      this.getNews()
      if(!this.ws) {
        this.connectWs()
      }
    })
    this.connectWs()
    this.getNews()
    
    this.avatar = localStorage.getItem('avatar')
    this.$bus.on('changeAvatar', avatar => {
      this.avatar = avatar
    })
    this.$bus.on('changeMemberLogo',member => {
      this.groupId = member.groupId
      var endtime = member.endDate
      var stringTime = Date.parse(new Date(endtime))
      var nowtime = new Date().getTime()
      if(nowtime>stringTime){ //如果当前时间的时间戳大于会员截止时间的时间戳 则会员过期
        this.overdue = true
      }else{
        this.overdue = false
      }
    })
    // if(localStorage.getItem('teacherType')){
    //   this.teacherType = localStorage.getItem('teacherType')
    // }
  },
  created() {
  },
}
</script>

